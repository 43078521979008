<template>
  <!-- 联系我们/客户咨询 -->
  <div>
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img
        :src="banner"
      /> -->
      <span
        >{{ $t("indexnav.Customer Enquiries")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Customer Enquiries</i
        ></span
      >
    </div>
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/contactus/default">{{ $t("indexnav.Contact Us") }}</a> >{{
        $t("indexnav.Customer Enquiries")
      }} -->
      <bread-crumb />
    </section>
    <figure>
      <div>
        <h2>{{ $t("Contact US.公司名") }}</h2>
        <div>
          <div>
            <dl>
              <dd class="icon">
                <img src="../../assets/images/contact/contract_email.jpg" />
              </dd>
              <dd>
                <strong>{{ $t("Contact US.投资者关系管理咨询") }}</strong
                ><a href="mailto:IR@jxr-fertility.com">
                    <span v-if="this.$store.state.nowlanguage == 'zh'">{{sampleList[0].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'tr'">{{tranditionalList[0].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'en'">{{enList[0].value}}</span>
                </a>
              </dd>
              <dd>
                <strong>{{ $t("Contact US.公关及合作咨询") }}</strong
                ><a href="mailto:PR@jxr-fertility.com">
                   <span v-if="this.$store.state.nowlanguage == 'zh'">{{sampleList[1].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'tr'">{{tranditionalList[1].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'en'">{{enList[1].value}}</span>
                </a>
              </dd>
              <dd>
                <strong>{{ $t("Contact US.人才招聘咨询") }}</strong
                ><a href="mailto:HR@jxr-fertility.com">
                    <span v-if="this.$store.state.nowlanguage == 'zh'">{{sampleList[2].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'tr'">{{tranditionalList[2].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'en'">{{enList[2].value}}</span>
                </a>
              </dd>
              <dd class="icon">
                <img src="../../assets/images/contact/contract_tel.jpg" />
              </dd>
              <dd>
                <strong>{{ $t("Contact US.锦欣学苑") }}</strong>
                <span> 028-63268681</span>
              </dd>
              <!-- <dd>
                <strong>{{ $t("Contact US.24小时热线") }}</strong
                ><br v-if="this.$store.state.nowlanguage === 'en'" /><a
                  href="tel:400-042-6868">
                   <span v-if="this.$store.state.nowlanguage == 'zh'">{{sampleList[3].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'tr'">{{tranditionalList[3].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'en'">{{enList[3].value}}</span>
                  </a
                >
              </dd> -->
              <dd class="icon">
                <img src="../../assets/images/contact/contract_map.jpg" />
              </dd>
              <dd>
                <strong>{{ $t("Contact US.地址") }}</strong>
                   <span v-if="this.$store.state.nowlanguage == 'zh'">{{sampleList[4].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'tr'">{{tranditionalList[4].value}}</span>
                    <span v-if="this.$store.state.nowlanguage == 'en'">{{enList[4].value}}</span>
              </dd>
            </dl>
          </div>
          <div class="kehuImg">
             <img v-if="this.$store.state.nowlanguage == 'zh'" :src="sampleList[5].value"/>
                <img v-if="this.$store.state.nowlanguage == 'tr'" :src="tranditionalList[5].value"/>
                <img v-if="this.$store.state.nowlanguage == 'en'" :src="enList[5].value"/>
          </div>
        </div>
      </div>
    </figure>
  </div>
</template>

<script>
import { TouZiZheChaXun } from "@/api/investors";
import { PageType } from '@/utils/constant';
import {getAction} from '@/api/manage'

export default {
  data() {
    return {
        sampleList:{},
        tranditionalList:{},
        enList:{},
      banner: ''
    };
  },
  created() {
    // 初始化banner
    let that = this
    getAction('/jeecg-system/common/frontend/advertise/active_list', { typeCode: PageType.KEHUZIXUN }).then(res => {
      console.log(res);
      if (res.result.records instanceof Array && res.result.records.length > 0) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    TouZiZheChaXun().then((res) => {
      let sample = []; //简体
      let tranditional = []; //繁体
      let en = []; //英文
      sample = res.result.filter((item) => {
        return item.key.includes("sample_customer");
      });
      tranditional = res.result.filter((item) => {
        return item.key.includes("tranditional_customer");
      });
      en = res.result.filter((item) => {
        return item.key.includes("en_customer");
      });
      this.sampleList = { ...sample };
      this.tranditionalList = { ...tranditional };
      this.enList = { ...en };
    });
    console.log(this.sampleList,this.tranditionalList,this.enList);
  },
};
</script>

<style scoped lang='less'>
.kehuImg{
    >img{
        max-height: 400px;
    }
}
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
figure {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure > div {
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 3em 5em;
}

figure > div h2 {
  color: #165da3;
  border-bottom: 1px solid #c1c0c0;
  padding-bottom: 1em;
  margin-bottom: 1em;
  letter-spacing: 2px;
  font-size: 1.3em;
  font-weight: bold;
}

figure > div div {
  display: flex;
}

figure > div div div:nth-child(1) {
  display: flex;
  width: 40%;
}

figure > div div div:nth-child(1) dl dd {
  line-height: 3em;
}

figure > div div div:nth-child(1) dl dd.icon {
  height: 2.5em;
}

figure > div div div:nth-child(1) dl dd.icon img {
  width: 3em;
}

figure > div div div:nth-child(1) dl dd strong {
  font-size: 1em;
  color: #165da3;
  font-weight: bold;
}

figure > div div div:nth-child(1) dl dd a {
  color: #221815;
}
figure > div div div:nth-child(1) dl dd:last-child {
  color: #666;
}

figure > div div div:nth-child(2) {
  width: 60%;
}

figure > div div div:nth-child(2) img {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure > div {
    padding: 2em;
  }
}

@media screen and (max-width: 1000px) {
  figure > div div div:nth-child(1) {
    width: 100%;
  }

  figure > div div div:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  figure div {
    flex-direction: column;
  }

  #allmap {
    height: 300px;
  }

  #allmap + div {
    padding: 1em;
  }

  #allmap + div dl {
    font-size: 1rem;
  }
}
</style>
